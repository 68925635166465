@media all and (min-width: 480px) {
    .Settings {
      padding: 60px 0;
      margin: 0 auto;
      max-width: 320px;
    }
  }
  .Settings .LoaderButton:last-child {
    margin-top: 15px;
  }
  